<template>
  <div class="system-menu-container">
    <el-dialog title="录制新提示音" v-model="isShowDialog" width="50%">
      <el-divider></el-divider>
      <el-form :model="ruleForm" size="small" label-width="50px">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item :label="$t('名称')">
              <el-input v-model="ruleForm.name" placeholder="名称" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item :label="$t('文本')" prop="comment">
              <el-input v-model="ruleForm.comment" :rows="3" type="textarea"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item :label="$t('操作')" prop="comment">
              <el-button size="small" type="primary">{{ $t('转语音') }}</el-button>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
            <el-form-item :label="$t('试听')" class="">
              <div class="footer">
                <audio :src="ruleForm.greetLongUrl" controls></audio>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">取 消</el-button>
					<el-button type="primary" @click="onSubmit" size="small">录 制</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref
} from 'vue';
import {
  tableData,
} from '../../groupData';

export default {
  name: 'recordPromptTone',
  setup() {
    const state       = reactive({
      isShowDialog: false,
      tableData,
      ruleForm: {
        name: '',
        phone: '5060',
        bingfa: '125',
        record: '',
        from: '',
        input: 'SIP',
      },
    });
    const num         = ref(1);
    // 打开弹窗
    const openDialog  = () => {
      state.isShowDialog = true;
    };
    // 关闭弹窗
    const closeDialog = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel    = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit    = () => {
      closeDialog(); // 关闭弹窗
      // setBackEndControlRefreshRoutes() // 刷新菜单，未进行后端接口测试
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm    = () => {
      state.ruleForm.name   = '';
      state.ruleForm.phone  = '';
      state.ruleForm.record = '';
      state.ruleForm.from   = '';
    };
    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      num,
      ...toRefs(state),
    };
  },
  data() {
    const generateData = () => {
      const data = []
      for (let i = 1; i <= 15; i++) {
        data.push({
          value: i,
          desc: `Option ${i}`,
        })
      }
      return data
    }
    return {
      data: generateData(),
      value: [],
    }
  },
};
</script>
<style lang="scss" scoped>
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
