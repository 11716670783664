<template>
  <div class="system-user-container">
    <div class="system-user-search mb15">
      <div>
        <div>
          <el-button size="small" type="primary" @click="onOpenBatchAdd">{{ $t('添加') }}</el-button>
          <el-button size="small" type="info" @click="deleteMedias">{{ $t('删除') }}</el-button>
        </div>
      </div>
    </div>
    <el-table :data="tableData" stripe style="width: 100%" class="table" id="out-table"
              @selection-change="selectionChange" @sort-change="changeTableSort">

      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="name" :label="$t('媒体名称')" show-overflow-tooltip></el-table-column>
      <!--      <el-table-column prop="path" :label="$t('录制')">-->
      <!--        <template #default="scope">-->
      <!--          <el-button size="mini" type="primary" icon="el-icon-microphone" circle @click="onOpenEditMenu(scope.row)">-->
      <!--          </el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column :label="$t('录音文件选项')" width="200" align="center">
        <template #="scope">
          <div class="ill">
            <div class="main-icon" @click="toggleAudio(scope.$index)">
              <i :class="scope.row.audioIcon"></i>
            </div>
            <i class="el-icon-download" @click="downAudio(scope.$index)"></i>
            <div class="main-time">
              {{ parseInt(scope.row.currentTime) }}s/{{ scope.row.audio && parseInt(scope.row.audio.duration) }}s
            </div>
            <el-progress class="progress" @click="changeProgress($event, scope.row)" :percentage="scope.row.progress"
                         :show-text="false"></el-progress>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" :label="$t('创建时间')" show-overflow-tooltip
                       sortable="custom" width="180"></el-table-column>
      <el-table-column prop="path" :label="$t('操作')" width="100" fixed="right">
        <template #default="scope">
          <el-button size="mini" type="text" @click="onRowDel(scope.row)"
                     class="u-delete">{{ $t('删除') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                   class="mt15" :pager-count="5" :page-count="pageCount" :current-page="pageNum" background
                   :page-size="pageSize"
                   layout="total, prev, pager, next, jumper" :total="total">
    </el-pagination>
    <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                   :page-size="pageSize" small layout=" prev, pager, next" :page-count="pageCount"
                   :current-page="pageNum"
                   :total="total" class="mt15"></el-pagination>
    <RecordPromptTone ref="recordPromptToneRef"/>
    <UploadPromptTone ref="uploadPromptToneRef" @print="print"/>
    <EditPromptTone ref="editPromptToneRef"/>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  ref,
  onMounted
} from 'vue';
import {useI18n} from "vue-i18n";
import RecordPromptTone from './recordPromptTone.vue';
import UploadPromptTone from './uploadPromptTone.vue';
import EditPromptTone from './editPromptTone.vue';
import {
  getMedia,
  deleteMedia,
  batchdelMedia
} from '@/http/media.api.js';
import {
  ElMessageBox,
  ElNotification
} from 'element-plus';

export default {
  name: 'callRecords',
  components: {
    RecordPromptTone,
    UploadPromptTone,
    EditPromptTone
  },
  setup(props, context) {
    const {t} = useI18n()
    let state = reactive({
      warningTone: [],
      input: ref(''),
      pageSize: 5, //每页数据条数
      pageNum: 1, //页码
      total: 0, //总数据条数
      pageCount: 0, //总页数
      selectionList: [],
      clientWidth: '',
      sortType: '',
      sortBy: ''
    });
    onMounted(() => {
      blackList(state);
      state.clientWidth = window.innerWidth;
    })
    const print                 = () => {
      blackList(state);
    }
    const recordPromptToneRef   = ref();
    // 录制新提示音
    const onOpenAddMenu         = () => {
      recordPromptToneRef.value.openDialog();
    };
    const uploadPromptToneRef   = ref();
    // 上传提示音
    const onOpenBatchAdd        = () => {
      uploadPromptToneRef.value.openDialog();
    };
    const editPromptToneRef     = ref();
    // 重新录制提示音
    const onOpenEditMenu        = (row) => {
      editPromptToneRef.value.openDialog(row);
    };
    // 当前行删除
    const onRowDel              = (row) => {
      ElMessageBox.confirm(t("你确定要删除该媒体吗"), t("提示"), {
        confirmButtonText: t("确定"),
        cancelButtonText: t("取消"),
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            let params = {
              id: row.id
            }
            deleteMedia(params).then((res) => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("删除成功"),
                  type: 'success',
                  duration: 2000,
                });
                blackList(state);

              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })

          }
        },
      });
    };
    const selectionChange       = (list) => {
      let array           = list.map(item => {
        return item.id
      })
      state.selectionList = array;
    }
    //批量删除
    const deleteMedias          = () => {
      if (state.selectionList.length === 0) {
        ElNotification({
          title: t("提示"),
          message: t("请选择要删除的媒体"),
          type: "warning",
          duration: 2000,
        });
        return;
      } else {
        ElMessageBox.confirm(t("你确定要删除该媒体吗"), t("提示"), {
          confirmButtonText: t("确定"),
          cancelButtonText: t("取消"),
          type: "warning",
          callback: (action) => {
            if (action === "confirm") {
              let params = {
                ids: state.selectionList
              }
              batchdelMedia(params).then(res => {
                if (res.code == 200) {
                  ElNotification({
                    title: t("成功"),
                    message: t("删除成功"),
                    type: "success",
                    duration: 2000,
                  });
                  blackList(state);
                } else {
                  ElNotification({
                    title: t('失败'),
                    message: res.msg,
                    type: 'error',
                    duration: 2000,
                  });
                }
              })
            }
          }
        })
      }
    }
    const changeTableSort       = (column) => {
      state.sortBy   = column.prop;
      state.sortType = column.order;
      blackList(state)
    }
    // 分页改变
    const onHandleSizeChange    = (val) => {
      state.pageSize = val;
      blackList(state)
    };
    // 分页改变
    const onHandleCurrentChange = (val) => {
      state.pageNum = val;
      blackList(state)
    };

    function blackList(state) {
      let params = {
        page: state.pageNum,
        sort_type: state.sortType,
        sort_by: state.sortBy
      }
      getMedia(params).then(res => {
        if (res.code == 200) {
          state.warningTone = res.data.list;
          state.pageSize    = res.data.length;
          state.pageCount   = res.data.pages;
          state.total       = res.data.rows;
          context.emit('changeTag', res.tag);
          // for(let i=0;i<res.data.list.length;i++){
          //   state.warningTone.push(res.data.list[i])
          // }
          initTable();
        } else {
          ElNotification({
            title: '失败',
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      })
    }

    const initTable = () => {
      tableData.value = state.warningTone;
      tableData.value.map(item => {
        item.paused      = true; //是否处于暂停状态
        item.audioIcon   = "el-icon-video-play"; //播放按钮图标 默认播放
        item.currentTime = 0; //当前时间
        item.progress    = 0; //当前时间/总时长*100
        let audio        = document.createElement("audio") //创建audio
        audio.src        = item.url //设置路径
        audio.oncanplay  = function () {
          item.audio = audio
        }
        audio.load() //加载当前audio
      })
    }
    let tableData   = ref([]);
    let divArr      = [];

    const toggleAudio = (index) => {
      if (tableData.value[index].paused) {
        tableData.value.map(item => { //暂停所有正在播放的音频
          if (!item.paused) {
            item.paused    = true;
            item.audioIcon = "el-icon-video-play"
            item.audio.pause();
          }
        })
        tableData.value[index].paused    = false;
        tableData.value[index].audioIcon = "el-icon-video-pause"
        tableData.value[index].audio.play();
      } else {
        tableData.value[index].paused    = true;
        tableData.value[index].audioIcon = "el-icon-video-play"
        tableData.value[index].audio.pause();
      }
      tableData.value[index].audio.addEventListener('ended', function () {
        tableData.value[index].paused    = true;
        tableData.value[index].audioIcon = "el-icon-video-play"
      }, false);
      tableData.value[index].audio.addEventListener('timeupdate', function () {
        tableData.value[index].currentTime = this.currentTime
        tableData.value[index].progress    = parseInt((this.currentTime / this.duration) * 100)
      }, false);
    }

    const downAudio = (index) => {
      var name = tableData.value[index].name;
      var url  = tableData.value[index].url;
      fetch(url).then(res => res.blob()).then(blob => {
        const a         = document.createElement("a");
        const objectUrl = window.URL.createObjectURL(blob);
        a.download      = name;
        a.href          = objectUrl;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
      })
    }

    function getPercent(e) {
      let mouseX        = e.pageX; //鼠标距离屏幕左侧的距离
      let progress      = e.srcElement; //进度条div元素
      let progressWidth = progress.offsetWidth; //进度条宽度
      let progressLeft  = progress.offsetLeft; //当前点击进度条距离父元素左侧的距离
      let parent        = progress.offsetParent; //当前点击进度条的父元素
      let currentPonit  = 0; //鼠标点击当前进度条的位置
      let percent       = 0; //百分比,最后保留两位小数
      while (parent != null) { //循环获取当前元素距离父元素右侧的左边距 直到当前元素没有父元素
        progressLeft += parent.offsetLeft;
        parent = parent.offsetParent;
      }
      currentPonit = mouseX - progressLeft; //当前所在位置 等于鼠标距屏幕左边距 - 元素距离屏幕左边距
      // 百分比等于鼠标当前位置/进度条宽度
      percent = parseFloat(((currentPonit / progressWidth) * 100).toFixed(2));
      return percent
    }

    const changeProgress = (e, row) => {
      let percent     = getPercent(e); //获取百分比
      let currentTime = row.audio.duration * percent / 100; //根据百分比获取当前播放时间

      tableData.value.map(item => { //暂停所有正在播放的音频
        if (!item.paused) {
          item.paused    = true;
          item.audioIcon = "el-icon-video-play"
          item.audio.pause();
        }
      })

      row.audio.currentTime = currentTime; //设置当前播放时间(点击位置)
      row.audio.play();
      row.paused    = false; //切换状态
      row.audioIcon = "el-icon-video-pause" //切换状态图标

      //两个监听事件 不多说了
      row.audio.addEventListener('ended', function () {
        row.paused    = true;
        row.audioIcon = "el-icon-video-play"
      }, false);
      row.audio.addEventListener('timeupdate', function () {
        row.currentTime = this.currentTime
        row.progress    = parseInt((this.currentTime / this.duration) * 100)
      }, false);
    }

    return {
      ...toRefs(state),
      onRowDel,
      changeTableSort,
      onHandleSizeChange,
      onHandleCurrentChange,
      tableData,
      divArr,
      toggleAudio,
      downAudio,
      changeProgress,
      recordPromptToneRef,
      onOpenAddMenu,
      uploadPromptToneRef,
      onOpenBatchAdd,
      editPromptToneRef,
      onOpenEditMenu,
      selectionChange,
      deleteMedias,
      print,
      blackList,
      initTable,
    };
  },
}
</script>
<style lang="scss" scoped>
.table {
  :deep(.hidden-columns) {
    visibility: visible !important;
    position: relative !important;
    z-index: 1 !important;
  }
}

.title {
  font-weight: 500;
  font-size: 1.2rem;
}

.system-user-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mt15 {
  text-align: center;
  margin-top: 15px;
}

.ml10 {
  margin-left: 10px;
}

.main-icon {
  font-size: 18px;
  text-align: center;
  transition: all .3s;
  display: inline-block;
}

.ill i {
  padding: 0 5px;
}
</style>
