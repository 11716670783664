/**
 * 部门列表
 * @returns 返回模拟数据
 */
export const data = [{
    id: 1,
    label: 'Level one 1',
    children: [{
      id: 4,
      label: 'Level two 1-1',
      children: [{
          id: 9,
          label: 'Level three 1-1-1',
        },
        {
          id: 10,
          label: 'Level three 1-1-2',
        },
      ],
    }, ],
  },
  {
    id: 2,
    label: 'Level one 2',
    children: [{
        id: 5,
        label: 'Level two 2-1',
      },
      {
        id: 6,
        label: 'Level two 2-2',
      },
    ],
  },
  {
    id: 3,
    label: 'Level one 3',
    children: [{
        id: 7,
        label: 'Level two 3-1',
      },
      {
        id: 8,
        label: 'Level two 3-2',
      },
    ],
    defaultProps: {
      children: 'children',
      label: 'label',
    },
  },
];
/**
 * 事件日志
 * @returns 返回模拟数据
 */
 export const tableData = [
	{
    num: '825',
    Name: '0',
    duty: '前端',
    jurisdiction: '国内',
    record: '√',
  },
  {
    icon: 'el-alert__icon el-icon-success',
    num: '825',
    Name: '1',
    duty: '前端',
    department: '技术',
    jurisdiction: '国内',
    record: '√',
  },
  {
    icon: 'el-alert__icon el-icon-success',
    num: '825',
    Name: '2',
    duty: '前端',
    department: '技术',
    jurisdiction: '国内',
    record: '√',
  },
  {
    icon: 'el-alert__icon el-icon-success',
    num: '825',
    Name: '3',
    duty: '前端',
    department: '技术',
    jurisdiction: '国内',
    record: '√',
  },
  {
    icon: 'el-alert__icon el-icon-success',
    num: '825',
    Name: '3',
    duty: '前端',
    department: '技术',
    jurisdiction: '国内',
    record: '√',
  },
  {
    icon: 'el-alert__icon el-icon-success',
    num: '825',
    Name: '3',
    duty: '前端',
    department: '技术',
    jurisdiction: '国内',
    record: '√',
  },
  {
    icon: 'el-alert__icon el-icon-success',
    num: '825',
    Name: '3',
    duty: '前端',
    department: '技术',
    jurisdiction: '国内',
    record: '√',
  },
  {
    icon: 'el-alert__icon el-icon-success',
    num: '825',
    Name: '3',
    duty: '前端',
    department: '技术',
    jurisdiction: '国内',
    record: '√',
  },
  {
    icon: 'el-alert__icon el-icon-success',
    num: '825',
    Name: '3',
    duty: '前端',
    department: '技术',
    jurisdiction: '国内',
    record: '√',
  },
  {
    icon: 'el-alert__icon el-icon-success',
    num: '825',
    Name: '3',
    duty: '前端',
    department: '技术',
    jurisdiction: '国内',
    record: '√',
  },
  {
    icon: 'el-alert__icon el-icon-success',
    num: '825',
    Name: '3',
    duty: '前端',
    department: '技术',
    jurisdiction: '国内',
    record: '√',
  },
  {
    icon: 'el-alert__icon el-icon-success',
    num: '825',
    Name: '3',
    duty: '前端',
    department: '技术',
    jurisdiction: '国内',
    record: '√',
  },
  {
    icon: 'el-alert__icon el-icon-success',
    num: '825',
    Name: '3',
    duty: '前端',
    department: '技术',
    jurisdiction: '国内',
    record: '√',
  },
  {
    icon: 'el-alert__icon el-icon-success',
    num: '825',
    Name: '3',
    duty: '前端',
    department: '技术',
    jurisdiction: '国内',
    record: '√',
  },
];


/**
* 分组
* @returns 返回模拟数据
*/
export const groupingData = [
{
  Name: '0',
  Phone: '12345678901',
  icon: 'el-alert__icon el-icon-success',
  genre: '分组',
  quantity: '1',
  updateTime: '2021/9/24',
},
{
  Name: '1',
  Phone: '12345678901',
  icon: 'el-alert__icon el-icon-error',
  genre: '分组',
  quantity: '2',
  updateTime: '2021/9/24',
},
{
  Name: '2',
  Phone: '12345678901',
  icon: 'el-alert__icon el-icon-success',
  genre: '分组',
  quantity: '3',
  updateTime: '2021/9/24',
},
{
  Name: '3',
  Phone: '12345678901',
  icon: 'el-alert__icon el-icon-success',
  genre: '分组',
  quantity: '4',
  updateTime: '2021/9/24',
},
{
  Name: '4',
  Phone: '12345678901',
  icon: 'el-alert__icon el-icon-success',
  genre: '分组',
  quantity: '5',
  updateTime: '2021/9/24',
},
{
  Name: '5',
  Phone: '12345678901',
  icon: 'el-alert__icon el-icon-success',
  genre: '分组',
  quantity: '6',
  updateTime: '2021/9/24',
},
];

/**
* 提示音
* @returns 返回模拟数据
*/
export const warningTone = [
  {
    id: '0',
    name: '欢迎语',
    url: '/static/music/huanying.wav',
  },
  {
    id: '1',
    name: '下班时间',
    url: '/static/music/xiaban.wav',
  },
  {
    id: '2',
    name: '忙线中',
    url: '/static/music/mangxian.wav',
  },
  {
    id: '3',
    name: '无人接听',
    url: '/static/music/wuren.wav',
  },
  {
    id: '4',
    name: '输入错误',
    url: '/static/music/shuru.wav',
  },
  ];