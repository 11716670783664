<template>
  <el-card class="!border-none mb10 status-tag" v-show="sysTitle" shadow="never">
    <div style="display: flex">
      <el-alert
          type="warning"
          :title="sysTitle"
          :closable="false"
          show-icon
          style="flex-direction: row"
      />
      <a href="/#/setUp/systemSetup"
         style="width:60px;height:40px;line-height: 40px;margin-left: 5px;color:rgb(59 82 224)">{{ $t('更新') }}</a>
    </div>
  </el-card>
  <div>
    <el-card shadow="hover">
      <el-tabs type="border-card">
        <!-- <el-tab-pane label="提示音选项">
          <PromptOption/>
        </el-tab-pane>
        <el-tab-pane label="等待音乐">
          <MusicOnHold/>
        </el-tab-pane> -->
        <el-tab-pane :label="$t('自定义提示音')">
          <CustomizePromptTone @changeTag="changeTag"/>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
// import PromptOption from './promptTone/promptOption';
// import MusicOnHold from './promptTone/musicOnHold';
import CustomizePromptTone from './promptTone/customizePromptTone';
import {reactive, toRefs} from "vue";
import router from "@/router";

export default {
  name: 'promptTone',
  components: {
    // PromptOption,
    // MusicOnHold,
    CustomizePromptTone
  },
  setup() {
    const state       = reactive({
      sysTitle: "",
    });
    const changeTag   = (msg) => {
      if (msg) {
        state.sysTitle = msg;
        router.push('/media');
      } else {
        state.sysTitle = '';
      }
    }
    return {
      ...toRefs(state),
      changeTag,
    }
  }
}
</script>
