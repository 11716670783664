<template>
	<div class="system-menu-container">
		<el-dialog :title="ruleForm.name" v-model="isShowDialog" width="60%">
			<el-divider></el-divider>
			<el-form :model="ruleForm" size="small" label-width="90px">
				<el-row :gutter="35">
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
						<el-form-item :label="$t('名称')">
							<el-input v-model="ruleForm.name" :placeholder="$t('名称')" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
						<el-form-item :label="$t('分机号')">
							<el-select v-model="ruleForm.bingfa" placeholder="" filterable allow-create default-first-option>
								<el-option label="125" value="typer0"></el-option>
								<el-option label="825" value="typer1"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{$t('取消')}}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{$t('录制')}}</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		reactive,
		toRefs,
		ref
	} from 'vue';
	import {
		tableData,
	} from '../../groupData';
	export default {
		name: 'incomingCalleditMenu',
		setup() {
			const state = reactive({
				isShowDialog: false,
				tableData,
				ruleForm: {
					name: '',
					phone: '5060',
					bingfa: '无限制',
					record: '',
					from: '',
					input: 'SIP',
				},
			});
			const num = ref(1);
			// 打开弹窗
			const openDialog = (row) => {
				state.ruleForm.name = row.Name;
				state.ruleForm.phone = row.Phone;
				state.ruleForm.bingfa = row.bingfa;
				state.ruleForm.record = row.record;
				state.ruleForm.from = row.from;
				state.ruleForm.input = row.input;
				state.isShowDialog = true;
			};
			// 关闭弹窗
			const closeDialog = () => {
				state.isShowDialog = false;
			};
			// 取消
			const onCancel = () => {
				closeDialog();
				initForm();
			};
			// 新增
			const onSubmit = () => {
				closeDialog(); // 关闭弹窗
				// setBackEndControlRefreshRoutes() // 刷新菜单，未进行后端接口测试
			};
			// 表单初始化，方法：`resetFields()` 无法使用
			const initForm = () => {
				state.ruleForm.name = '';
				state.ruleForm.phone = '';
				state.ruleForm.record = '';
				state.ruleForm.from = '';
			};
			return {
				openDialog,
				closeDialog,
				onCancel,
				onSubmit,
				num,
				...toRefs(state),
			};
		},
		data() {
			const generateData = () => {
				const data = []
				for (let i = 1; i <= 15; i++) {
					data.push({
						value: i,
						desc: `Option ${i}`,
					})
				}
				return data
			}
			return {
				data: generateData(),
				value: [],
			}
		},
	};
</script>
<style lang="scss" scoped>
	.el-dialog__body {
		padding-top: 0;
	}

	.column {
		flex-direction: column;
	}

	.el-divider--horizontal {
		margin-top: 15px;
	}

	.el-button--primary.is-disabled {
		display: block;
	}

	.el-button [class*=el-icon-]+span {
		margin-left: 0;
	}

	.el-transfer__buttons {
		padding: 0 10px;
	}

	.member {
		font-size: 1rem;
		margin-left: 25px;
		margin-bottom: 5px;
	}

	.el-transfer {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.el-transfer {
		--el-transfer-panel-width: 40%;
		margin-bottom: 35px;
	}

</style>
